<template>
  <div class="page-content">
    <breadcrumb title="Reiseplan bearbeiten" :goBack="true" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 mx-auto">
          <form class="edit" action="#!">
            <div class="form-group">
              <label>Name deines Reiseplans</label>
              <input type="text" class="form-control title" v-model="form.name" @blur="validateListTitle" placeholder="Titel">
              <span class="validation-error" v-show="showListNameError">Geben Sie einen Namen für die Liste ein.</span>
            </div>
            <div class="form-group">
              <label>Beschreibung (optional)</label>
              <textarea cols="30" rows="6" class="form-control" v-model="form.description" placeholder="Erzähle uns mehr über deine Merkliste..."></textarea>    
            </div>
            <button class="btn mt-4 mb-4 d-block mx-auto save-changes" @click="dispatchUpdateCollection">Speichern</button>
          </form>
          <div class="row">
            <div class=" col-xl-4 col-lg-4 col-sm-4 col-6 card-style" v-if="contents!==null" v-for="(content, index) in contents" :key="index">
              <event-card class="event-card" v-if="content.content_type_name=='angebot'" :item="content" :desktopAuto="true" />
              <location-card class="location-card" v-if="content.content_type_name=='institution'" :item="content" :desktopAuto="true" />
            </div>
          </div>

          <p v-if="false">Tipp: Ändere die Reihenfolge per drag &amp; drop</p>
          <div class="footer-margin"></div>
        </div>
      </div>
    </div>

    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  import { showLoader, hideLoader } from '@/utils/helpers';
  import Resource from '@/api/resource';
  const collectionResource = new Resource('collections');

  export default {
    name: 'EditReiseplan',
    components: {
      LocationCard: () => import('@/components/cards/LocationCard'),
      EventCard: () => import('@/components/cards/EventCard'),
      Breadcrumb: () => import('@/components/layout/Breadcrumb'),
      CardSlider: () => import('@/components/CardSlider'),
    },
    data: () => ({
      collection: null,
      showListNameError: false,
      form: {
        name: "",
        description: '',
      },
    }),
    computed: {
      id() {
        return this.$route.params.id;
      },
      title(){
        if(this.collection!==null){
          return this.collection.name;
        }
        return null;
      },
      contents(){
        if(this.collection!=null && this.collection.contents!=null && this.collection.contents.length>0){
          return this.collection.contents;
        }
        return null;
      },
    },
    created() {
      this.getCollection();
    },
    methods: {
      showLoader,
      hideLoader,
      validateListTitle(){
        if(!this.form.name!=""){
          this.showListNameError=true;
          return false;
        }
        else{
          this.showListNameError=false;
          return true;
        }
      },
      getCollection() {
        this.loader = this.showLoader(this.loader);
        collectionResource.get(this.id)
        .then(response => {
          this.collection = response.data;
          this.form.name = this.collection.name;
          if(this.collection.description !== null){
            this.form.description = this.collection.description;
          }
          this.form.type = this.collection.type;
        })
        .catch(error => {
          console.log(error);

          this.$notify({
            type: 'error',
            duration: 2500,
            title: 'Keine Berechtigung',
            text: 'Sie haben nicht die benötigten Berechtigungen um diese Liste einzusehen.'
          });

          if(this.user==null){
            this.$router.push({name:'login'});
          }

        })
        .finally(() => {
          this.loader = this.hideLoader(this.loader);
        });
      },
      dispatchUpdateCollection(e) {
        e.preventDefault();
        if(!this.validateListTitle()){
          return;
        }

        this.loader = this.showLoader(this.loader);

        collectionResource.update(this.collection.id,this.form).then(() => {
          this.$notify({
            duration: 2500,
            title: 'Liste umbenannt!',
            text: 'Die Liste wurde erfolgreich bearbeitet.'
          });

        })
        .finally(() => {
          this.loader = this.hideLoader(this.loader);
          this.$router.push({ name: 'TripView', params: {id: this.id } });
        });
      },
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .card {
    max-width: 300px;
    width: auto !important;
    min-width: 150px !important;

    @media(min-width:992px){
      min-height: 240px !important;
    }
  }

  .title {
   border: 1px solid #ced4da !important;
   border-radius: 0.25rem;
 }

 .card-style {
  padding-right: 0px;
  padding-bottom: 10px;

  @media (max-width: 400px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  & a {
    height: 100%;
    width: 100%;
    min-width: 100px;
  }
}

.card {
  margin-left: 5px !important;
}
</style>